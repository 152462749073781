import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AccessiblePageTitleContainer from '@client/containers/accessible-page-title.container';
import Feathers404 from '@client/components/Feathers404';
import AutoCompleteSearchContainer from '@client/containers/autocomplete-search.container';
import theme from '@client/css-modules/404.css';

type PageProps = {
  pageLoaded: (pageDetails: PageDetails) => void;
};

const Page404: React.FunctionComponent<PageProps> = (props) => {
  const [isClientSide, setIsClientSide] = React.useState(false);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const pageDetails: PageDetails = {
      fromPage: urlSearchParams.get('fromPage'),
      status: urlSearchParams.get('status'),
    };
    setIsClientSide(true);
    props.pageLoaded(pageDetails);
  }, []);

  return (
    <>
      <AccessiblePageTitleContainer />
      <div className={theme.page404}>
        <p className={theme.text404}>
          {"Sorry, the page you're looking "}
          <br className={theme.breakMobile} />
          {"for can't be found."}
        </p>
        <div className={theme.FeathersAndSearch}>
          <Feathers404 />
          {isClientSide && (
            <AutoCompleteSearchContainer
              theme={theme}
              withoutBackgroundScreen
            />
          )}
        </div>
      </div>
    </>
  );
};

export type PageDetails = {
  fromPage?: string | null;
  status?: string | null;
  errorMessage?: string | null;
};

const mapDispatchToProps = (dispatch) => ({
  pageLoaded: (pageDetails: PageDetails) => {
    dispatch({
      type: '404',
      meta: {
        analytics: {
          event: '404_PAGE_LOADED',
          props: pageDetails,
        },
      },
    });
  },
});

export default connect(() => ({}), mapDispatchToProps)(Page404);
