import anime from 'animejs';
import { Component } from 'react';

import theme from '@client/css-modules/Feathers404.css';
import feather from 'assets/images/feather_bottom_no_shadow@2x.png';
import shadow from 'assets/images/feather_shadow@3x.svg';
import featherFlipped from 'assets/images/feather_top_no_shadow_flipped@2x.png';

export default class Feathers404 extends Component<{}, {}> {
  animTimeout: number = 0;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.initAnimation();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.clearTimeout(this.animTimeout);
  }

  initAnimation = () => {
    const firstFeather = document.querySelector('.' + theme.featherFirst);
    const firstFeatherImg = document.querySelector('.' + theme.featherFirstImg);
    const secondFeather = document.querySelector('.' + theme.featherSecond);
    const secondFeatherImg = document.querySelector(
      '.' + theme.featherSecondImg
    );
    const firstFeatherShadow = document.querySelector(
      '.' + theme.featherFirstShadow
    );
    const secondFeatherShadow = document.querySelector(
      '.' + theme.featherSecondShadow
    );
    const clientHeightFeatherContainer = (
      document.querySelector('.' + theme.featherContainer) as Element
    ).clientHeight;

    if (typeof anime !== 'undefined') {
      let animateFeather1 = anime.timeline();

      animateFeather1
        .add({
          targets: firstFeatherShadow,
          translateY: (clientHeightFeatherContainer / 6) * 4 + 45,
          scale: 1.2,
          duration: 100,
        })
        .add({
          targets: firstFeatherImg,
          opacity: 1,
          scale: 1.2,
          easing: 'easeInOutQuad',
          duration: 1100,
        })
        .add({
          targets: firstFeather,
          translateX: 200,
          translateY: clientHeightFeatherContainer / 6,
          easing: 'easeInOutQuad',
          duration: 1100,
          rotate: -30,
          offset: '-=1100', // Starts 1100ms before the previous animation ends
        })
        .add({
          targets: firstFeather,
          translateX: 0,
          translateY: (clientHeightFeatherContainer / 6) * 2,
          easing: 'easeInOutQuad',
          duration: 1200,
          rotate: 0,
        })
        .add({
          targets: firstFeather,
          translateX: 200,
          translateY: (clientHeightFeatherContainer / 6) * 3,
          easing: 'easeInOutQuad',
          duration: 1300,
          rotate: -30,
        })
        .add({
          targets: firstFeather,
          translateX: 0,
          translateY: (clientHeightFeatherContainer / 6) * 4,
          easing: 'easeInOutQuad',
          duration: 1400,
          rotate: 0,
        })
        .add({
          targets: firstFeatherShadow,
          opacity: 1,
          easing: 'easeInOutQuad',
          duration: 1400,
          offset: '-=500', // Starts 500ms before the previous animation ends
        });

      // anime is a global set by a script added to header
      let animateFeather2 = anime.timeline();

      animateFeather2
        .add({
          targets: secondFeatherShadow,
          translateY: (clientHeightFeatherContainer / 8) * 4 + 42,
          scale: 0.8,
          duration: 100,
        })
        .add({
          targets: secondFeatherImg,
          easing: 'easeInOutQuad',
          scale: 0.8,
          opacity: 0,
          duration: 1500,
        })
        .add({
          targets: secondFeatherImg,
          easing: 'easeInOutQuad',
          opacity: 1,
          duration: 500,
        })
        .add({
          targets: secondFeather,
          translateX: 100,
          translateY: clientHeightFeatherContainer / 8,
          easing: 'easeInOutQuad',
          duration: 1000,
          rotate: -44,
          offset: '-=500', // Starts 500ms before the previous animation ends
        })
        .add({
          targets: secondFeather,
          translateX: -100,
          translateY: (clientHeightFeatherContainer / 8) * 2,
          easing: 'easeInOutQuad',
          duration: 1100,
          rotate: 0,
        })
        .add({
          targets: secondFeather,
          translateX: 100,
          translateY: (clientHeightFeatherContainer / 8) * 3,
          easing: 'easeInOutQuad',
          rotate: -42,
          duration: 1200,
        })
        .add({
          targets: secondFeather,
          translateX: 0,
          translateY: (clientHeightFeatherContainer / 8) * 4,
          easing: 'easeInOutQuad',
          rotate: 0,
          duration: 1300,
        })
        .add({
          targets: secondFeatherShadow,
          easing: 'easeInOutQuad',
          opacity: 1,
          duration: 900,
          offset: '-=800', // Starts 800ms before the previous animation ends
        });
    }
  };

  handleResize = () => {
    const firstFeather = document.querySelector(
      '.' + theme.featherFirst
    ) as any;
    const secondFeather = document.querySelector(
      '.' + theme.featherSecond
    ) as any;
    const firstFeatherShadow = document.querySelector(
      '.' + theme.featherFirstShadow
    ) as any;
    const secondFeatherShadow = document.querySelector(
      '.' + theme.featherSecondShadow
    ) as any;

    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (
      firstFeather &&
      firstFeatherShadow &&
      secondFeather &&
      secondFeatherShadow
    ) {
      if (width < 768) {
        // medium screen
        firstFeather.style.left = '26%';
        firstFeatherShadow.style.left = '26%';
        secondFeather.style.left = '46%';
        secondFeatherShadow.style.left = '46%';
      } else if (width >= 768 && width < 1030) {
        // large screen
        firstFeather.style.left = '35%';
        firstFeatherShadow.style.left = '35%';
        secondFeather.style.left = '48%';
        secondFeatherShadow.style.left = '48%';
      } else if (width >= 1030) {
        // large screen
        firstFeather.style.left = '40%';
        firstFeatherShadow.style.left = '40%';
        secondFeather.style.left = '50%';
        secondFeatherShadow.style.left = '50%';
      }
    }
  };

  render() {
    return (
      <div className={theme.featherContainer}>
        <div>
          <div className={theme.featherSecond}>
            <img
              alt=""
              src={featherFlipped}
              className={theme.featherSecondImg}
            />
          </div>

          <div className={theme.featherSecondShadow}>
            <img alt="" src={shadow} />
          </div>

          <div className={theme.featherFirst}>
            <img alt="" src={feather} className={theme.featherFirstImg} />
          </div>

          <div className={theme.featherFirstShadow}>
            <img alt="" src={shadow} />
          </div>
        </div>
      </div>
    );
  }
}
